import styled from "styled-components"
import { COLOR } from "utils/color"

export const BreakVerticalLine = styled.div<{ height?: string }>`
  position: absolute;
  bottom: -10px;
  left: 16px;
  width: 1px;
  height: ${({ height }) => (height ? height : "7px")};
  border: 1px solid ${COLOR.SUPER_LIGHT_GREY};
  background-color: ${COLOR.SUPER_LIGHT_GREY};
`

export const BreakHorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  margin: 10px 10px 10px 25px;
  border: 1px solid ${COLOR.SUPER_LIGHT_GREY};
  background-color: ${COLOR.SUPER_LIGHT_GREY};
`

export const EventWrap = styled.div<{ marginBottom?: string }>`
  width: 100%;
  max-width: 690px;
  display: flex;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "16px")};
  &:nth-last-child(1) {
    ${BreakVerticalLine} {
      display: none;
    }
    margin-bottom: 0;
  }
`

export const IconWrap = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  width: 32px;
  min-width: 32px;
  justify-content: center;
  margin-right: 14px;
`

export const EventIconWrapS = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  min-width: 24px;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 50%;
  i.icon {
    margin: 0;
  }
`

export const EventIconWrapL = styled.div<{ color: string; bg: string; marginBottom?: boolean }>`
  display: flex;
  width: 32px;
  height: 32px;
  min-width: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ bg }) => bg};
  i.grey.icon {
    color: ${({ color }) => color} !important;
    margin: 0;
    margin-bottom: ${({ marginBottom }) => (marginBottom ? "2px" : 0)};
    margin-top: ${({ marginBottom }) => (marginBottom ? 0 : "2px")};
  }
`

export const TimeWrap = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins, serif;
  width: 25px;
  font-size: 11px;
  line-height: 13.5px;
  text-align: center;
  color: ${COLOR.LIGHT_GREY};
  margin-right: 15px;
  font-weight: 600;
`

export const EventDesc = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins, serif;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: ${COLOR.DARK_GREY};
  font-weight: 300;
  margin-right: 10px;
  max-width: 568px;
  width: 100%;
`

export const DescWithEmoji = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const EmojiWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  border-radius: 50%;
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  letter-spacing: -0.5px;
`

export const ReasonWrap = styled.div`
  margin-left: 21px;
  font-family: Poppins, serif;
  font-weight: 300;
  font-size: 9px;
  color: ${COLOR.GREY};
`

export const DetailsWrap = styled(ReasonWrap)`
  text-align: start;
`

export const PrincipalDescWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Poppins, serif;
  font-size: 10px;
  gap: 3px;
  padding: 6px;
  background-color: ${COLOR.WHITE};
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(207, 205, 205, 0.25);
  width: 100%;
`

export const PrincipalDescLine = styled(PrincipalDescWrap)`
  padding: 0;
  align-items: center;
  flex-direction: row;
  gap: 2px;
  box-shadow: none;
`

export const BoldText = styled.div`
  font-family: Poppins, serif;
  font-size: 10px;
  font-weight: 700;
  color: black;
`

export const RedBoldText = styled(BoldText)`
  color: ${COLOR.DARK_CORAIL};
`

export const DatePrincipalWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins, serif;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 700;
  width: 30px;
  margin-right: 12px;
  margin-top: 1px;
`
