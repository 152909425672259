import {
  BoldText,
  DescWithEmoji,
  DetailsWrap,
  EmojiWrap,
  PrincipalDescLine,
  PrincipalDescWrap,
  ReasonWrap,
  RedBoldText,
} from "components/ParcelEvent/ParcelEvent.styled"
import React, { ReactElement } from "react"
import {
  formatEventCollectDate,
  getEmitterDetails,
  getEventDimensions,
  getEventWeight,
} from "components/ParcelEvent/utils"
import { EventEntity, EventType } from "types/event.types"
import { displayParcelFlag } from "utils/displayParcelFlag"
import { AdminParcelEventReason, ParcelFlag } from "types/parcel.types"
import { getEventReason } from "components/ParcelEvent/getEventReason"
import { ParcelEventCode } from "types/parcel-event.types"
import { Popup } from "semantic-ui-react"
import { getKeeperOkKoDetails } from "components/ParcelEvent/getKeeperOkKoDetails"
import { COLOR } from "utils/color"

interface EventTypeObject {
  iconName: string
  type: EventType
  iconBG: string
  iconColor: string
  showReason: boolean
  text: ReactElement | string
}

function EventEmoji({ event }: { event: EventEntity }): ReactElement {
  const emitterDetails = getEmitterDetails(event.title)

  if (!!emitterDetails)
    return (
      <Popup
        content={emitterDetails.byEmitterText}
        size={"tiny"}
        position={"bottom center"}
        trigger={<EmojiWrap>{emitterDetails.emoji}</EmojiWrap>}
      />
    )

  return <></>
}

/**
 * When adding a new event, follow these steps:
 * 1. Create a new 'else if' block for the event using 'event.eventCode'.
 * 2. Define the properties like 'iconName', 'type', 'iconBG', 'iconColor', 'showReason', and 'text'.
 * 3. Customize the values for the new event as per your requirements.
 */
export function getEventType(event: EventEntity): EventTypeObject {
  if (event.eventCode === ParcelEventCode.PARCEL_CREATED) {
    return {
      iconName: "plus",
      type: EventType.PRINCIPAL,
      iconBG: COLOR.MEDIUM_BLUE,
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Annonce du colis pour le Keeper{" "}
            <BoldText>
              {event.data.keeper?.firstName} {event.data.keeper?.lastName}
            </BoldText>
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_BTOC_DELIVERY_IN_TRANSIT) {
    return {
      iconName: "truck",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: (
        <DescWithEmoji>
          <EventEmoji event={event} />
          Colis en transit
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_BTOC_DELIVERY_OUT_FOR_DELIVERY) {
    return {
      iconName: "truck",
      type: EventType.PRINCIPAL,
      iconBG: COLOR.PURPLE,
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Mise en livraison du colis <BoldText>par le transporteur</BoldText>
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_BTOC_DELIVERY_DELIVERY_FAILURE) {
    return {
      iconName: "close",
      type: EventType.PRINCIPAL,
      iconBG: "#B11631",
      iconColor: "#FFFFFF",
      showReason: true,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Échec de livraison Keeper déclaré
          </PrincipalDescLine>{" "}
          <ReasonWrap>
            Raison : {getEventReason(event.eventReason)} ({getKeeperOkKoDetails(event.description)})
          </ReasonWrap>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_BTOC_DELIVERY_PARCEL_LOST) {
    return {
      iconName: "close",
      type: EventType.PRINCIPAL,
      iconBG: "#000000",
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis déclaré perdu
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_BTOC_DELIVERY_RETURNED_TO_SENDER) {
    return {
      iconName: "arrow right",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: (
        <DescWithEmoji>
          <EventEmoji event={event} />
          Colis retourné à l&apos;expéditeur
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_BTOC_DELIVERY_DELIVERY_CANCELLED) {
    return {
      iconName: "close",
      type: EventType.PRINCIPAL,
      iconBG: "#000000",
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis annulé par le transporteur
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_BTOC_DELIVERY_DELIVERY_SUCCESS) {
    const isEventReason = !(event.eventReason === null || event.eventReason === "")
    const type = isEventReason ? EventType.PRINCIPAL : EventType.SECONDARY
    const iconBG = isEventReason ? "#000000" : "#E9E9E9"
    const iconColor = isEventReason ? "#FFFFFF" : "#7B7B7B"
    const eventText =
      event.eventReason === AdminParcelEventReason.DELIVERED_TO_PICKUP_POINT
        ? "Colis livré directement en point relais"
        : "Colis livré directement au destinataire"
    return {
      iconName: "check",
      type: type,
      iconBG: iconBG,
      iconColor: iconColor,
      showReason: false,
      text: isEventReason ? (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            {eventText}
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ) : (
        <DescWithEmoji>
          <EventEmoji event={event} />
          Colis déclaré livré <BoldText>au Keeper</BoldText>
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_RECEPTION_FAILURE) {
    return {
      iconName: "close",
      type: EventType.PRINCIPAL,
      iconBG: "#B11631",
      iconColor: "#FFFFFF",
      showReason: true,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis refusé <BoldText>par le Keeper</BoldText>
          </PrincipalDescLine>
          <ReasonWrap>Raison : {getEventReason(event.eventReason)}</ReasonWrap>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_RECIPIENT_DELIVERY_FAILURE) {
    return {
      iconName: "close",
      type: EventType.PRINCIPAL,
      iconBG: "#B11631",
      iconColor: "#FFFFFF",
      showReason: true,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis refusé <BoldText>par le destinataire</BoldText>
          </PrincipalDescLine>
          <ReasonWrap>Raison : {getEventReason(event.eventReason)}</ReasonWrap>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_RETURNING_SUCCESS) {
    return {
      iconName: "arrow right",
      type: EventType.PRINCIPAL,
      iconBG: COLOR.TURQUOISE,
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis retourné <BoldText>par le Keeper</BoldText>
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_RETURNING_FAILURE) {
    return {
      iconName: "close",
      type: EventType.PRINCIPAL,
      iconBG: "#B11631",
      iconColor: "#FFFFFF",
      showReason: true,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Échec de collecte du colis <BoldText>chez le Keeper</BoldText>
          </PrincipalDescLine>
          <ReasonWrap>Raison : {getEventReason(event.eventReason)}</ReasonWrap>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_RETURN_TO_PLAN) {
    return {
      iconName: "clock",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: (
        <DescWithEmoji>
          <EventEmoji event={event} />
          Retour du colis à planifier
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_RETURN_REQUESTED) {
    const { referenceCode, pickupDate, dimensions, weight } =
      event?.data?.returningShipment?.picking?.parcelShipmentMeta?.[0] || {}
    const reason = event.eventReason
    return {
      iconName: "truck",
      type: EventType.PRINCIPAL,
      iconBG: COLOR.TURQUOISE,
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Retour du colis demandé
          </PrincipalDescLine>
          <DetailsWrap>
            {[
              pickupDate && `Date de collecte : ${formatEventCollectDate(pickupDate)}`,
              dimensions && `Taille : ${getEventDimensions(dimensions)}`,
              weight !== undefined && `Poids : ${getEventWeight(weight)}`,
              reason && `Raison : ${getEventReason(reason)}`,
              referenceCode && `Le code de référence: ${referenceCode}`,
            ]
              .filter(Boolean)
              .join(", ")}
          </DetailsWrap>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_RETURN_COMPLETED) {
    const { eventReason } = event
    return {
      iconName: "check",
      type: EventType.PRINCIPAL,
      iconBG: COLOR.CITRUS_YELLOW,
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} /> Retour du colis terminé
          </PrincipalDescLine>
          {eventReason && <ReasonWrap>Raison : {getEventReason(event.eventReason)}</ReasonWrap>}
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.MAIL_NOTICE) {
    return {
      iconName: "mail",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: event.title,
    }
  } else if (event.eventCode === ParcelEventCode.SMS_NOTICE) {
    return {
      iconName: "talk",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: "SMS envoyé au destinataire",
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_MAIL_NOTICE) {
    return {
      iconName: "mail",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: event.title,
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_SMS_NOTICE) {
    return {
      iconName: "talk",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: "SMS envoyé au Keeper",
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_PUSH_NOTICE) {
    return {
      iconName: "mail",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: event.title,
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_SLEEPING) {
    return {
      iconName: "wait",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: "Colis dormant - Chez le Keeper depuis 5 jours",
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_UNSCANNED) {
    return {
      iconName: "close",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: "Colis non scanné",
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_REASSIGNED) {
    return {
      iconName: "exchange",
      type: EventType.PRINCIPAL,
      iconBG: COLOR.MEDIUM_BLUE,
      iconColor: "#FFFFFF",
      showReason: true,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis réassigné au Keeper
            <BoldText>
              {event.data.keeper?.firstName} {event.data.keeper?.lastName}
            </BoldText>
          </PrincipalDescLine>
          <ReasonWrap>Raison : {getEventReason(event.eventReason)}</ReasonWrap>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_CANCELLED) {
    return {
      iconName: "close",
      type: EventType.PRINCIPAL,
      iconBG: "#000000",
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>Colis annulé</PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_BACK_IN_TRANSIT) {
    return {
      iconName: "truck",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: "Colis de retour en transit",
    }
  } else if (event.eventCode === ParcelEventCode.CANCEL_RETURN_REQUESTED) {
    return {
      iconName: "close",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: "Demande de retour colis annulée",
    }
  } else if (event.eventCode === ParcelEventCode.DHL_PARCEL_STILL_WITH_KEEPER) {
    return {
      iconName: "arrow left",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: (
        <DescWithEmoji>
          <EventEmoji event={event} />
          Colis chez le Keeper (DHL event)
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_DELIVERY_SERVICE_ON) {
    return {
      iconName: "street view",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: 'Activation du service "Livraison par le Keeper"',
    }
  } else if (event.eventCode === ParcelEventCode.RECIPIENT_CONNECTED_ON_CHAT) {
    return {
      iconName: "wifi",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: "Le destinataire s'est connecté sur le chat",
    }
  } else if (event.eventCode === ParcelEventCode.RECIPIENT_CLICK_SUPPORT_LINK) {
    return {
      iconName: "volume control phone",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: 'Le destinataire a cliqué sur le lien "Support client" dans le chat',
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_CLICK_CONTACT) {
    return {
      iconName: "phone",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: <DescWithEmoji>Le Keeper a cliqué sur la fiche contact du destinataire</DescWithEmoji>,
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_UNSCANNED_NOT_RECEIVED_BY_KEEPER) {
    return {
      iconName: "close",
      type: EventType.PRINCIPAL,
      iconBG: "#000000",
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis déclaré non reçu par le Keeper
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_PERSONAL_DATA_ANONYMIZED) {
    return {
      iconName: "question circle outline",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: (
        <DescWithEmoji>
          <EventEmoji event={event} /> Colis anonymisé
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_ARCHIVED) {
    return {
      iconName: "question circle outline",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: (
        <DescWithEmoji>
          <EventEmoji event={event} />
          Colis archivé
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_FLAG_ADDED) {
    return {
      iconName: "flag",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: (
        <DescWithEmoji>
          <EventEmoji event={event} />
          Ajout du flag <RedBoldText>{displayParcelFlag(event.eventReason as ParcelFlag)}</RedBoldText>
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_FLAG_REMOVED) {
    return {
      iconName: "flag",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: (
        <DescWithEmoji>
          <EventEmoji event={event} />
          Retrait du flag <RedBoldText>{displayParcelFlag(event.eventReason as ParcelFlag)}</RedBoldText>
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.COLLECT_MEETING_UPDATE) {
    return {
      iconName: "flag",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: (
        <DescWithEmoji>
          <EventEmoji event={event} />
          Mise à jour du rendez-vous de collecte
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_RECEPTION_SUCCESS) {
    return {
      iconName: "arrow left",
      type: EventType.PRINCIPAL,
      iconBG: "#B11631",
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis réceptionné <BoldText>par le Keeper</BoldText>
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_RECIPIENT_DELIVERY_SUCCESS) {
    return {
      iconName: "check",
      type: EventType.PRINCIPAL,
      iconBG: COLOR.CITRUS_YELLOW,
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis livré au destinataire{" "}
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  }
  // CtoB EVENTS
  else if (event.eventCode === ParcelEventCode.KEEPER_DROPOFF_SUCCESS) {
    return {
      iconName: "arrow left",
      type: EventType.PRINCIPAL,
      iconBG: "#B11631",
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis déposé chez le Keeper{" "}
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_DROPOFF_DELIVERY_SUCCESS) {
    return {
      iconName: "check",
      type: EventType.PRINCIPAL,
      iconBG: COLOR.CITRUS_YELLOW,
      iconColor: "#FFFFFF",
      showReason: false,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Colis collecté par le transporteur{" "}
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.KEEPER_DROPOFF_DELIVERY_FAILURE) {
    return {
      iconName: "close",
      type: EventType.PRINCIPAL,
      iconBG: "#B11631",
      iconColor: "#FFFFFF",
      showReason: true,
      text: (
        <PrincipalDescWrap>
          <PrincipalDescLine>
            <EventEmoji event={event} />
            Échec de collecte par le transporteur{" "}
          </PrincipalDescLine>
        </PrincipalDescWrap>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_CTOB_DELIVERY_PICKUP_SUCCESS) {
    return {
      iconName: "truck",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: <DescWithEmoji>Colis collecté chez le Keeper déclaré par le transporteur</DescWithEmoji>,
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_CTOB_DELIVERY_PICKUP_FAILURE) {
    return {
      iconName: "truck",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: <DescWithEmoji>Échec de collecte déclaré par le transporteur </DescWithEmoji>,
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_BTOC_RETURN_PICKUP_SUCCESS) {
    return {
      iconName: "truck",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: <DescWithEmoji>Le transporteur a récupéré le colis retour chez le Keeper </DescWithEmoji>,
    }
  } else if (event.eventCode === ParcelEventCode.CARRIER_BTOC_RETURN_PICKUP_FAILURE) {
    return {
      iconName: "truck",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: (
        <DescWithEmoji>
          Le transporteur a rencontré des problèmes lors de la récupération du colis retour chez le Keeper{" "}
        </DescWithEmoji>
      ),
    }
  } else if (event.eventCode === ParcelEventCode.RETURN_REQUEST_REMOVED) {
    return {
      iconName: "close",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: <DescWithEmoji>Demande de retour par le Keeper supprimée </DescWithEmoji>,
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_SLEEPING_MISSED_APPOINTMENT) {
    return {
      iconName: "wait",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: <DescWithEmoji>Rendez-vous Keeper-Desti manqué - Colis dormant </DescWithEmoji>,
    }
  } else if (event.eventCode === ParcelEventCode.PARCEL_BILLING_UPDATED) {
    return {
      iconName: "check",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: <DescWithEmoji>Facturation colis mise à jour </DescWithEmoji>,
    }
  } else
    return {
      iconName: "question circle outline",
      type: EventType.SECONDARY,
      iconBG: "#E9E9E9",
      iconColor: "#7B7B7B",
      showReason: false,
      text: event.eventCode,
    }
}
