import { EventEntity, EventType } from "types/event.types"
import { BreakVerticalLine, EventIconWrapL, EventIconWrapS } from "components/ParcelEvent/ParcelEvent.styled"
import { getEventType } from "components/ParcelEvent/getEventType"
import React, { ReactElement } from "react"
import { Icon, SemanticICONS } from "semantic-ui-react"
import { format } from "date-fns-tz"
import moment from "moment/moment"

export const getEmitterDetails = (text: string): { emoji?: string; eventTitle?: string; byEmitterText?: string } => {
  if (text.includes("[ADMIN]"))
    return { emoji: "👨‍💻", eventTitle: text.replace("[ADMIN]", ""), byEmitterText: "Action d'un Admin" }
  if (text.includes("[CARRIER]"))
    return { emoji: "🚛", eventTitle: text.replace("[CARRIER]", ""), byEmitterText: "Transmis par le Transporteur" }
  if (text.includes("[KEEPER]"))
    return { emoji: "👤", eventTitle: text.replace("[KEEPER]", ""), byEmitterText: "Action du Keeper" }
  if (text.includes("[SYSTEM]"))
    return { emoji: "⚙", eventTitle: text.replace("[SYSTEM]", ""), byEmitterText: "Généré par le Système" }

  return {}
}

export const getIcon = (event: EventEntity, isEventReason: boolean, showDate: boolean): ReactElement => {
  const iconProps = getEventType(event)
  const isTruckIcon = iconProps.iconName === "truck"
  if (iconProps.type === EventType.PRINCIPAL) {
    return (
      <>
        <EventIconWrapL color={iconProps.iconColor} bg={iconProps.iconBG} marginBottom={isTruckIcon}>
          {isTruckIcon ? (
            <Icon name={iconProps.iconName as SemanticICONS} color="grey" />
          ) : (
            <Icon size="large" name={iconProps.iconName as SemanticICONS} color="grey" />
          )}
        </EventIconWrapL>
        <BreakVerticalLine height={isEventReason ? "16px" : showDate ? "11px" : "7px"} />
      </>
    )
  } else {
    return (
      <>
        <EventIconWrapS>
          <Icon size="small" name={iconProps.iconName as SemanticICONS} color="grey" />
        </EventIconWrapS>
        <BreakVerticalLine />
      </>
    )
  }
}

/**
 This function is designed to determine whether events occurred on different days by comparing the dates on which they occurred.
 */
export const areEventsOnDifferentDays = (eventDate1: Date, eventDate2: Date): boolean => {
  if (!eventDate1 || !eventDate2) return false

  /** Format the given dates to 'dd/MM/yyyy' format */
  const date1 = format(new Date(eventDate1), "dd/MM/yyyy")
  const date2 = format(new Date(eventDate2), "dd/MM/yyyy")

  return date1 !== date2
}

export const formatEventCollectDate = (date: string): string => {
  return moment(date).format("DD.MM.YYYY")
}

export const getEventDimensions = (dimensions: string): string | undefined => {
  if (dimensions === "S") {
    return "Petit (rentre dans un sac à dos)"
  } else if (dimensions === "M") {
    return "Moyen (rentre dans un coffre de voiture)"
  } else if (dimensions === "L") {
    return "Grand (rentre dans une voiture sièges baissés)"
  }
}

export const getEventWeight = (weight?: number): string => {
  if (!weight || weight < 1) {
    return "Léger (moins de 1kg)"
  } else if (weight < 5) {
    return "Moyen (entre 1kg et 5kg)"
  } else {
    return "Lourd (plus de 5kg)"
  }
}
